import React from "react";
import ContactUs from "../components/ContactUs";
import './Contact.scss';

const Contact=()=>{
    return(
        <div id="contact" className="contact">
            <ContactUs/>
        </div>
    )
}
export default Contact;